export default [
  {
    path: '/moteurs',
    name: 'engineList',
    component: () => import('@/views/Engine/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/moteurs/creation',
    name: 'engineCreate',
    component: () => import('@/views/Engine/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/moteurs/:engineId',
    name: 'engineDetails',
    component: () => import('@/views/Engine/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
