export default [
  {
    path: '/accueil/selection',
    name: 'selectionDetails',
    component: () => import('@/views/Home/Selection/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
