<template>
  <v-app v-if="authenticated">
    <LeftMenu></LeftMenu>

    <TopMenu></TopMenu>

    <v-main>
      <div class="app-content-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
import LeftMenu from './Menu/LeftMenu.vue'
import TopMenu from './Menu/TopMenu.vue'
import Footer from './Footer.vue'

export default {
  components: {
    LeftMenu,
    TopMenu,
    Footer
  },
  computed: {
    authenticated () {
      if (this.$store.getters.isAuthenticated) {
        return true
      } else {
        this.redirectToHome()

        return false
      }
    }
  },
  methods: {
    redirectToHome () {
      this.$router.push({ name: 'home'})
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
