export default [
  {
    path: '/accueil/nuage-de-liens',
    name: 'linkCloudDetails',
    component: () => import('@/views/Home/LinkCloud/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
