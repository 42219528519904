import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import config from '../config'
import jwtDecode from 'jwt-decode'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null,
    authenticated: false,
    user: null,
    token: null,
    tokenExpiration: null,
    roles: [],
    lastRefresh: null,
    drawerOpen: true,
    handlingTranslationScroll: false,
    handlingCampingScroll: false,
    handlingSlideScroll: false,
    handlingSocialNetworkPostScroll: false,
    handlingWorksCouncilScroll: false,
    handlingContactWorksCouncilScroll: false,
    handlingContactGroupFormSheetScroll: false,
    handlingRegionSeoScroll: false,
    handlingPageScroll: false,
    handlingBlockScroll: false,
    handlingThematicScroll: false,
    entityStatus: null
  },
  mutations: {
    initStore: state => {
      state.token = localStorage.getItem('token')
      state.tokenExpiration = localStorage.getItem('token_expiration')
      state.user = JSON.parse(localStorage.getItem('user'))
      state.roles = JSON.parse(localStorage.getItem('roles'))
      state.lastRefresh = localStorage.getItem('last_refresh')
    },
    setError : (state, error) => {
      state.error = error
    },
    setAuthenticated: (state, authenticated) => {
      state.authenticated = authenticated
    },
    setToken: (state, token) => {
      localStorage.setItem('token', token)
      state.token = token
    },
    setTokenExpiration: (state, token) => {
      const tokenExpiration = token ? Date.now() + (3300 * 1000) : 0

      localStorage.setItem('token_expiration', tokenExpiration)
      state.tokenExpiration = tokenExpiration
    },
    setUser: (state, user) => {
      localStorage.setItem('user', user ? JSON.stringify(user) : null)
      state.user = user
    },
    setRoles: (state, roles) => {
      localStorage.setItem('roles', roles ? JSON.stringify(roles) : [])
      state.roles = roles
    },
    setLastRefresh: (state, lastRefresh) => {
      localStorage.setItem('last_refresh', lastRefresh)
      state.lastRefresh = lastRefresh
    },
    setDrawerOpen: (state, value) => {
      state.drawerOpen = value
    },
    setHandlingTranslationScroll: (state, status) => {
      state.handlingTranslationScroll = status
    },
    setHandlingCampingScroll: (state, status) => {
      state.handlingCampingScroll = status
    },
    setHandlingSlideScroll: (state, status) => {
      state.handlingSlideScroll = status
    },
    setHandlingSocialNetworkPostScroll: (state, status) => {
      state.handlingSocialNetworkPostScroll = status
    },
    setHandlingWorksCouncilScroll: (state, status) => {
      state.handlingWorksCouncilScroll = status
    },
    setHandlingContactWorksCouncilScroll: (state, status) => {
      state.handlingContactWorksCouncilScroll = status
    },
    setHandlingContactGroupFormSheetScroll: (state, status) => {
      state.handlingContactGroupFormSheetScroll = status
    },
    setHandlingRegionSeoScroll: (state, status) => {
      state.handlingRegionSeoScroll = status
    },
    setHandlingPageScroll: (state, status) => {
      state.handlingPageScroll = status
    },
    setHandlingBlockScroll: (state, status) => {
      state.handlingBlockScroll = status
    },
    setHandlingThematicScroll: (state, status) => {
      state.handlingThematicScroll = status
    },
    setEntityStatus: (state, status) => {
      state.entityStatus = status
    }
  },
  actions: {
    login: (state, data) => {
      Vue.prototype.$http
        .post(config.apiUrl + '/api/login_check', data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data
            const token = data.token
            const decodedTokenData = jwtDecode(token)

            state.commit('setToken', token)
            state.commit('setTokenExpiration', token)
            state.commit('setAuthenticated', true)
            state.commit('setLastRefresh', Date.now())

            state.dispatch('getUser', decodedTokenData)
          }
        })
        .catch(error => {
          const response = error.response

          if (!response) {
            state.commit('setError', 'Erreur réseau')

            return
          }

          const data = response.data

          if (response.status === 401) {
            let message = data.message

            state.commit('setError', message)
          }
        })
    },
    logout: state => {
      state.commit('setToken', null)
      state.commit('setTokenExpiration', null)
      state.commit('setUser', null)
      state.commit('setAuthenticated', false)
      state.commit('setRoles', [])
      state.commit('setLastRefresh', null)

      router.push({ name: 'home' })
    },
    getUser: (state, decodedTokenData) => {
      Vue.prototype.$http
        .get(config.apiUrl + '/api/users/' + decodedTokenData.userId, {
          headers: {
            Authorization: 'Bearer ' + state.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const user = response.data

            state.commit('setUser', user)

            router.push({ name: 'campingList' })
          }
        })
        .catch(() => {
        })
    }
  },
  getters: {
    isAuthenticated: (state, getters) => {
      return !getters.isTokenExpired
    },
    getToken: state => {
      return state.token
    },
    isTokenExpired: state => {
      return Date.now() > state.tokenExpiration
    },
    getError: state => {
      return state.error
    },
    getUser: state => {
      return state.user
    },
    getLastRefresh: state => {
      return state.lastRefresh
    },
    isDrawerOpen: state => {
      return state.drawerOpen
    },
    isHandlingTranslationScroll: state => {
      return state.handlingTranslationScroll
    },
    isHandlingCampingScroll: state => {
      return state.handlingCampingScroll
    },
    isHandlingSlideScroll: state => {
      return state.handlingSlideScroll
    },
    isHandlingSocialNetworkPostScroll: state => {
      return state.handlingSocialNetworkPostScroll
    },
    isHandlingWorksCouncilScroll: state => {
      return state.handlingWorksCouncilScroll
    },
    isHandlingContactWorksCouncilScroll: state => {
      return state.handlingContactWorksCouncilScroll
    },
    isHandlingContactGroupFormSheetScroll: state => {
      return state.handlingContactGroupFormSheetScroll
    },
    isHandlingRegionSeoScroll: state => {
      return state.handlingRegionSeoScroll
    },
    isHandlingPageScroll: state => {
      return state.handlingPageScroll
    },
    isHandlingBlockScroll: state => {
      return state.handlingBlockScroll
    },
    isHandlingThematicScroll: state => {
      return state.handlingThematicScroll
    },
    getEntityStatus: state => {
      return state.entityStatus
    }
  }
})
