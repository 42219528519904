export default [
  {
    path: '/accueil/slide/:locale',
    name: 'slideList',
    component: () => import('@/views/Home/Slide/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/accueil/slide/creation/:locale',
    name: 'slideCreate',
    component: () => import('@/views/Home/Slide/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/accueil/slide/:slideId/:locale',
    name: 'slideDetails',
    component: () => import('@/views/Home/Slide/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
