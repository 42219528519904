export default [
  {
    path: '/localisation/regions-catalogue',
    name: 'regionCatalogueList',
    component: () => import('@/views/Location/RegionCatalogue/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/localisation/regions-catalogue/creation',
    name: 'regionCatalogueCreate',
    component: () => import('@/views/Location/RegionCatalogue/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/localisation/regions-catalogue/:regionCatalogueId',
    name: 'regionCatalogueDetails',
    component: () => import('@/views/Location/RegionCatalogue/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
