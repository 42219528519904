export default [
  {
    path: '/campings',
    name: 'campingList',
    component: () => import('@/views/Camping/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/campings/:campingId',
    name: 'campingDetails',
    component: () => import('@/views/Camping/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
