export default [
  {
    path: '/localisation/regions-web',
    name: 'regionWebList',
    component: () => import('@/views/Location/RegionWeb/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/localisation/regions-web/creation',
    name: 'regionWebCreate',
    component: () => import('@/views/Location/RegionWeb/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/localisation/regions-web/:regionWebId',
    name: 'regionWebDetails',
    component: () => import('@/views/Location/RegionWeb/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
