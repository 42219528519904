export default [
  {
    path: '/contenu-editorial/pages',
    name: 'pageList',
    component: () => import('@/views/Content/Page/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/contenu-editorial/pages/creation',
    name: 'pageCreate',
    component: () => import('@/views/Content/Page/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/contenu-editorial/pages/:pageId',
    name: 'pageDetails',
    component: () => import('@/views/Content/Page/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
