export default [
  {
    path: '/contacts/fiches-groupe',
    name: 'contactGroupFormSheetList',
    component: () => import('@/views/Contact/GroupFormSheet/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/contacts/fiches-groupe/:contactGroupFormSheetId',
    name: 'contactGroupFormSheetDetails',
    component: () => import('@/views/Contact/GroupFormSheet/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
