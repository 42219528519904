<template>
  <v-footer
    app
    inset
    color="transparent"
    absolute
    height="56"
    class="px-0"
  >
    <div class="boxed-container w-full">
      <div class="mx-6 d-flex justify-space-between">
        <span> &copy; {{ new Date().getFullYear() }}
          <a
            href="https://www.Flower.com"
            class="text-decoration-none"
            target="_blank"
          >Flower Campings</a>
        </span>
        <span class="d-sm-inline d-none"></span>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
}
</script>
