export default [
  {
    path: '/localisation/hebergements',
    name: 'locationRentingList',
    component: () => import('@/views/Location/Renting/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/localisation/hebergements/creation',
    name: 'locationRentingCreate',
    component: () => import('@/views/Location/Renting/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/localisation/hebergements/:locationRentingId',
    name: 'locationRentingDetails',
    component: () => import('@/views/Location/Renting/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
