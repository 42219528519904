export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Security/Login.vue'),
    meta: {
      layout: 'blank'
    }
  }
]
