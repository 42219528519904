<template>
  <v-app-bar
    app
    flat
    absolute
    color="transparent"
  >
    <div class="boxed-container w-full">
      <div class="d-flex align-center mx-6">
        <v-app-bar-nav-icon
          class="d-block d-lg-none me-2"
          @click="handleDrawer()"
        ></v-app-bar-nav-icon>
        <!-- Left Content -->

        <v-spacer></v-spacer>

        <v-menu
          offset-y
          left
          nudge-bottom="14"
          min-width="230"
          content-class="user-profile-menu-content"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bottom
              color="success"
              overlap
              offset-x="12"
              offset-y="12"
              class="ms-4"
              dot
            >
              <v-btn
                class="mr-3"
                small
                color="info"
                @click="exportCatalogBackpacker()"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  {{ icons.mdiDatabaseSearchOutline }}
                </v-icon>
                <i class="fas fa-search"></i>
                Export Guide du routard
              </v-btn>

              <v-btn
                class="mr-3"
                small
                color="info"
                @click="clearCache()"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  {{ icons.mdiDatabaseSearchOutline }}
                </v-icon>
                <i class="fas fa-search"></i>
                Clear Cache
              </v-btn>

              <span
                class="menu-username"
                v-on="on"
              >
                {{ user.email }}
              </span>
              <v-avatar
                size="40px"
                v-bind="attrs"
                v-on="on"
              >
                <v-img :src="require('@/assets/images/avatars/3.png')"></v-img>
              </v-avatar>
            </v-badge>
          </template>
          <v-list>
            <div class="pb-3 pt-2">
              <v-badge
                bottom
                color="success"
                overlap
                offset-x="12"
                offset-y="12"
                class="ms-4"
                dot
              >
                <v-avatar size="40px">
                  <v-img :src="require('@/assets/images/avatars/3.png')"></v-img>
                </v-avatar>
              </v-badge>
              <div
                class="d-inline-flex flex-column justify-center ms-3"
                style="vertical-align:middle"
              >
                <span class="text--primary font-weight-semibold mb-n1">
                  {{ user.firstName ? user.firstName : '-' }} {{ user.lastName ? user.lastName : '-' }}
                </span>
              </div>
            </div>

            <v-divider></v-divider>

            <!-- Logout -->
            <v-list-item link>
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ icons.mdiLogoutVariant }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="logout()">
                  Déconnexion
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCheckboxMarkedOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant
} from '@mdi/js'
import Vue from 'vue'
import config from '@/config'

export default {
  data: () => ({
    icons: [
      mdiAccountOutline,
      mdiCheckboxMarkedOutline,
      mdiCogOutline,
      mdiCurrencyUsd,
      mdiHelpCircleOutline,
      mdiLogoutVariant
    ]
  }),
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    drawerOpen () {
      return this.$store.getters.isDrawerOpen
    }
  },
  methods: {
    handleDrawer () {
      this.$store.commit('setDrawerOpen', !this.drawerOpen)
    },
    exportCatalogBackpacker () {
      Vue.prototype.$http
        .get(config.apiUrl + '/api/export-catalog-backpacker', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'export-guide-du-routard.csv')
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch(() => {
        })
    },
    clearCache () {
      Vue.prototype.$http
        .get(config.apiUrl + '/api/clear-cache', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(() => {
        })
        .catch(() => {
        })
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}

.menu-username {
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
}

@media screen and (max-width: 1248px) {
  .menu-username {
    display: none;
  }
}
</style>
