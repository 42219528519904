export default [
  {
    path: '/contenu-editorial/blocks',
    name: 'blockList',
    component: () => import('@/views/Content/Block/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/contenu-editorial/blocks/creation',
    name: 'blockCreate',
    component: () => import('@/views/Content/Block/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/contenu-editorial/blocks/:blockId',
    name: 'blockDetails',
    component: () => import('@/views/Content/Block/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
