export default [
  {
    path: '/accueil/post-reseaux-sociaux',
    name: 'socialNetworkPostList',
    component: () => import('@/views/Home/SocialNetworkPost/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/accueil/post-reseaux-sociaux/creation',
    name: 'socialNetworkPostCreate',
    component: () => import('@/views/Home/SocialNetworkPost/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/accueil/post-reseaux-sociaux/:socialNetworkPostId',
    name: 'socialNetworkPostDetails',
    component: () => import('@/views/Home/SocialNetworkPost/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
