export default [
  {
    path: '/localisation/regions-seo',
    name: 'regionSeoList',
    component: () => import('@/views/Location/RegionSeo/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/localisation/regions-seo/creation',
    name: 'regionSeoCreate',
    component: () => import('@/views/Location/RegionSeo/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/localisation/regions-seo/:regionSeoId',
    name: 'regionSeoDetails',
    component: () => import('@/views/Location/RegionSeo/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
