<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent
  },
  computed : {
    resolveLayout () {
      const route = this.$route

      if (route.name === null) {
        return null
      } else if (route.meta.layout === 'blank') {
        return 'layout-blank'
      } else {
        return 'layout-content'
      }
    }
  }
}
</script>
