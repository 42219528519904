export default [
  {
    path: '/offres-speciales',
    name: 'specialOfferList',
    component: () => import('@/views/SpecialOffer/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/offres-speciales/creation',
    name: 'specialOfferCreate',
    component: () => import('@/views/SpecialOffer/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/offres-speciales/:specialOfferId',
    name: 'specialOfferDetails',
    component: () => import('@/views/SpecialOffer/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
