export default [
  {
    path: '/idees-sejours',
    name: 'thematicList',
    component: () => import('@/views/Thematic/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/idees-sejours/creation',
    name: 'thematicCreate',
    component: () => import('@/views/Thematic/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/idees-sejours/:thematicId',
    name: 'thematicDetails',
    component: () => import('@/views/Thematic/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
