export default [
  {
    path: '/utilisateurs',
    name: 'userList',
    component: () => import('@/views/User/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/utilisateurs/creation',
    name: 'userCreate',
    component: () => import('@/views/User/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/utilisateurs/:userId',
    name: 'userDetails',
    component: () => import('@/views/User/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
