export default [
  {
    path: '/seo/metadonnees',
    name: 'metadataList',
    component: () => import('@/views/Seo/Metadata/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/seo/metadonnees/creation',
    name: 'metadataCreate',
    component: () => import('@/views/Seo/Metadata/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/seo/metadonnees/:metadataId',
    name: 'metadataDetails',
    component: () => import('@/views/Seo/Metadata/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
