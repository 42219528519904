export default [
  {
    path: '/hebergement',
    name: 'rentingList',
    component: () => import('@/views/Renting/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/hebergement/creation',
    name: 'rentingCreate',
    component: () => import('@/views/Renting/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/hebergement/:rentingId',
    name: 'rentingDetails',
    component: () => import('@/views/Renting/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
