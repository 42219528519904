
export default [
  {
    path: '/service/categories',
    name: 'serviceCategoryList',
    component: () => import('@/views/Service/Category/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/service/categories/:serviceCategoryId',
    name: 'serviceCategoryDetails',
    component: () => import('@/views/Service/Category/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
