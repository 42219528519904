export default [
  {
    path: '/seo/parametres',
    name: 'metadataParameterList',
    component: () => import('@/views/Seo/MetadataParameter/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/seo/parametres/creation',
    name: 'metadataParameterCreate',
    component: () => import('@/views/Seo/MetadataParameter/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/seo/parametres/:metadataParameterRoute',
    name: 'metadataParameterDetails',
    component: () => import('@/views/Seo/MetadataParameter/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
