export default [
  {
    path: '/contacts/comite-entreprise',
    name: 'contactWorksCouncilList',
    component: () => import('@/views/Contact/WorksCouncil/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/contacts/comite-entreprise/:contactWorksCouncilId',
    name: 'contactWorksCouncilDetails',
    component: () => import('@/views/Contact/WorksCouncil/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
