export default [
  {
    path: '/comites-entreprise',
    name: 'worksCouncilList',
    component: () => import('@/views/WorksCouncil/List.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/comites-entreprise/creation',
    name: 'worksCouncilCreate',
    component: () => import('@/views/WorksCouncil/Create.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/comites-entreprise/:worksCouncilId',
    name: 'worksCouncilDetails',
    component: () => import('@/views/WorksCouncil/Details.vue'),
    meta: {
      layout: 'content'
    }
  }
]
